import { AIMMO_CORE_DOCS_URL_EN, AIMMO_CORE_DOCS_URL_KO } from '@aimmo/constants';
import { AimIconComponent } from '@aimmo/design-system/aim-icon/src';
import { I18nGuard, Lang } from '@aimmo/i18n';
import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  computed,
  DestroyRef,
  inject,
  input,
  OnInit,
  Renderer2,
  signal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Event, NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { GlobalCatchError } from '@falcon/common/decorators';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { route } from 'aimmo-core2/app/app.route.path';
import { StoreService } from 'aimmo-core2/app/core/store.service';
import { isAimmoCoreAzurePhase } from 'aimmo-core2/app/shared/util/env-util';
import { forkJoin, Observable, Subject, switchMap } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'aimmo-core-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TranslateModule,
    RouterLink,
    AimIconComponent,
    RouterLinkActive
  ]
})
export class MobileHeaderComponent implements OnInit, AfterViewInit {
  public readonly route = route;
  public readonly Lang = Lang;
  public readonly isOpen = signal(false);
  public readonly isLangMenuOpen = signal(false);
  public readonly isAimmoCoreAzurePhase = isAimmoCoreAzurePhase();
  public logoRouterLink = input('/');
  public hideLanguageSelector = input(false);
  public readonly AIMMO_CORE_DOCS_URL = computed<string>(() => this.store.currentUser()?.lang === Lang.EN ? AIMMO_CORE_DOCS_URL_EN : AIMMO_CORE_DOCS_URL_KO);
  private readonly i18nGuard = inject(I18nGuard);
  private readonly changeLangSource = new Subject<string>();
  private readonly destroyRef = inject(DestroyRef);


  constructor(
    private readonly store: StoreService,
    private readonly t: TranslateService,
    private readonly router: Router,
    private readonly renderer: Renderer2,
  ) {
  }

  public get isEngPage(): boolean {
    return this.t.currentLang === Lang.EN;
  }

  public get lang(): string {
    return this.t.currentLang;
  }

  @GlobalCatchError
  private get changeLangAction$(): Observable<string> {
    return this.changeLangSource.pipe(
      switchMap(lang => this.i18nGuard.setLanguage(lang)),
      // reload 하는 방법 말고 다른 방법이 있을지?
      tap(() => {
        this.saveScrollPosition();
        location.reload();
      })
    );
  }

  private get checkUpdateAndReloadAction$(): Observable<NavigationEnd> {
    return this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      tap(() => {
        this.isOpen.set(false);
        this.setScrollStrategy(this.isOpen());
      })
    );
  }

  public isCurrentPath(comparePath: string): boolean {
    return this.router.url === comparePath;
  }

  public ngAfterViewInit(): void {
    const savedScrollPosition = sessionStorage.getItem('aimmoCoreScrollPosition');
    if (savedScrollPosition) {
      setTimeout(() => window.scrollTo(0, parseInt(savedScrollPosition, 10)));
      sessionStorage.removeItem('aimmoCoreScrollPosition');
    }
  }

  public onChangeLang(lang: string): void {
    if (this.t.currentLang === lang) {
      return;
    }
    this.t.currentLang = lang;
    this.changeLangSource.next(lang);
  }

  public ngOnInit(): void {
    forkJoin([
      this.changeLangAction$,
      this.checkUpdateAndReloadAction$
    ]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  public onToggleOpen(): void {
    this.isOpen.set(!this.isOpen());
    this.setScrollStrategy(this.isOpen());
  }

  public onClickBackdrop(): void {
    this.isOpen.set(false);
    this.setScrollStrategy(false);
  }

  public onToggleLangMenuOpen(): void {
    this.isLangMenuOpen.set(!this.isLangMenuOpen());
  }

  private setScrollStrategy(isNavOpen: boolean): void {
    this.renderer.setStyle(document.body, 'overflow', isNavOpen ? 'hidden' : 'auto');
  }

  private saveScrollPosition(): void {
    sessionStorage.setItem('aimmoCoreScrollPosition', window.scrollY.toString());
  }
}
