import { ChangeDetectorRef, Component, EventEmitter, forwardRef, HostBinding, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AimOnOffToggleSize, AimOnOffToggleSizeType } from '../model';
import { AbstractValueAccessor } from './aim-onoff-toggle.abstract';

// TODO: OnPush 적용 추천.
@Component({
  selector: 'aim-onoff-toggle',
  templateUrl: './aim-onoff-toggle.component.html',
  styleUrls: ['./aim-onoff-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AimOnOffToggleComponent)
    }
  ],
  standalone: true,
  imports: [MatSlideToggleModule]
})
export class AimOnOffToggleComponent extends AbstractValueAccessor {
  @HostBinding('class.disabled')
  @Input() public disabled = false;
  @Input() public size: AimOnOffToggleSizeType = AimOnOffToggleSize.SMALL;

  @Output() private toggleChange = new EventEmitter<MatSlideToggleChange>();

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  public get value(): boolean {
    return this.innerValueSource;
  }

  @Input()
  public set value(v: boolean) {
    if (v !== this.innerValueSource) {
      this.innerValueSource = v;
      this.onChange(v);
    }
  }

  @HostBinding('class.sm')
  private get sizeSmall(): boolean {
    return this.size === AimOnOffToggleSize.SMALL;
  }

  @HostBinding('class.md')
  private get sizeMedium(): boolean {
    return this.size === AimOnOffToggleSize.MEDIUM;
  }

  public handleToggle(event: MatSlideToggleChange): void {
    this.value = event.checked;
    this.toggleChange.emit(event);
  }

  public writeValue(value: boolean): void {
    if (this.innerValueSource !== value) {
      this.innerValueSource = value;
      this.changeDetectorRef.markForCheck();
      // TODO: onChange 메서드를 이 부분에서 호출한 이유가 있는지 궁금함.
      //  onChange 메서드 값 전달 방향: value accessor -> formControl
      //  writeValue 메서드 값 전달 방향: formControl -> value accessor
      this.onChange(value);
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    if (this.disabled !== isDisabled) {
      this.disabled = isDisabled;
      this.changeDetectorRef.markForCheck();
    }
  }
}

