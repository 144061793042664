import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService, AuthStateForCore } from 'aimmo-core2/app/core/auth.service';
import { RouteService } from 'aimmo-core2/app/core/route.service';
import { isAimmoCoreAzurePhase } from 'aimmo-core2/app/shared/util/env-util';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard {

  constructor(
    private auth: AuthService,
    private routeService: RouteService
  ) {
  }

  public canActivate(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.getAuthState().pipe(
      switchMap(authState => {
        switch (authState) {
          case AuthStateForCore.authorized:
            return of(true);
          case AuthStateForCore.needSubscriptionActivate:
            return this.routeService.routeToSubscriptionActivate().pipe(map(() => false));
          // 인증 안된 경우 무조건 로그인 페이지로 가야함
          // aimmo-core 는 로그인 하지 않으면 진입 불가능.
          // aimmo-core-landing 이 로그인 전 페이지
          case AuthStateForCore.unauthorized:
            return isAimmoCoreAzurePhase()
              ? this.routeService.routeToAuthentication().pipe(map(() => false))
              : this.routeService.routeToLogin().pipe(map(() => false));
        }
      })
    );
  }

  public canActivateChild(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(activatedRoute, state);
  }
}
