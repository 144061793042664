import { I18nGuard } from '@aimmo/i18n';
import { inject } from '@angular/core';
import { ResolveFn, Routes } from '@angular/router';
import { AuthGuard } from 'aimmo-core2/app/core/guards/auth-guard.service';
import { HasAccessTokenGuard } from 'aimmo-core2/app/core/guards/has-access-token-guard.service';
import { WorkspaceAzureResolver } from 'aimmo-core2/app/core/workspace-azure.resolver';
import { WorkspaceCoreResolver } from 'aimmo-core2/app/core/workspace-core.resolver';
import { LayoutBlankComponent } from 'aimmo-core2/app/layout/blank/layout-blank.component';
import { LayoutTopBottomComponent } from 'aimmo-core2/app/layout/top-bottom/layout-top-bottom.component';
import { isAimmoCoreAzurePhase, isAimmoCorePhase } from 'aimmo-core2/app/shared/util/env-util';
import { route } from './app.route.path';

// todo resolver 많아질 경우 타입 공통 처리 필요
// https://app.asana.com/0/692598029547572/1207725563664612
export function azureOnlyResolveFactory(resolver: typeof WorkspaceAzureResolver): ResolveFn<object> {
  return (route, state) => {
    return isAimmoCoreAzurePhase()
      ? inject(resolver).resolve(route, state)
      : null;
  };
}

export function coreOnlyResolveFactory(resolver: typeof WorkspaceCoreResolver): ResolveFn<object> {
  return (route, state) => {
    return isAimmoCorePhase()
      ? inject(resolver).resolve(route, state)
      : null;
  };
}

export const routes: Routes = [
  {
    path: '',
    canActivate: [I18nGuard, AuthGuard],
    resolve: [
      azureOnlyResolveFactory(WorkspaceAzureResolver),
      coreOnlyResolveFactory(WorkspaceCoreResolver)
    ],
    component: LayoutTopBottomComponent,
    children: [
      {
        path: route.root.path,
        pathMatch: 'full',
        redirectTo: isAimmoCoreAzurePhase() ? route.dashboard.path : route.home.path
      },
      {
        path: route.home.path,
        pathMatch: 'full',
        loadComponent: () => import('./pages/home/home.component').then(m => m.HomeComponent)
      },
      {
        path: route.products.path,
        pathMatch: 'full',
        loadComponent: () => import('./pages/products/products.component').then(m => m.ProductsComponent)
      },
      {
        path: route.modelDetail.path,
        pathMatch: 'full',
        loadComponent: () => import('./pages/products/model-detail/model-detail.component').then(m => m.ModelDetailComponent)
      },
      {
        path: route.sdk.path,
        pathMatch: 'full',
        loadComponent: () => import('./pages/products/model-sdk/model-sdk.component').then(m => m.ModelSdkComponent)
      },
      {
        path: route.dashboard.path,
        loadChildren: () => import('./pages/dashboard/dashboard.routes').then(m => m.dashboardRoutes)
      },
      {
        path: route.pricing.path,
        pathMatch: 'full',
        loadComponent: () => import('./pages/pricing/pricing.component').then(m => m.PricingComponent)
      },
      {
        path: route.usecase.path,
        pathMatch: 'full',
        loadComponent: () => import('./pages/usecase/usecase.component').then(m => m.UsecaseComponent)
      },
      {
        path: route.studioIntroducing.path,
        loadComponent: () => import('./pages/studio-introducing/studio.component').then(m => m.StudioComponent)
      },
      {
        path: route.workspaceProfile.path,
        loadComponent: () => import('./pages/workspace-profile/workspace-profile.component').then(m => m.WorkspaceProfileComponent)
      },

    ]
  },
  {
    path: '',
    canActivate: [I18nGuard],
    component: LayoutBlankComponent,
    children: [
      {
        path: route.login.path,
        pathMatch: 'full',
        loadComponent: () => import('./pages/login/login.component').then(m => m.LoginComponent)
      },
      {
        path: route.authentication.path,
        pathMatch: 'full',
        loadComponent: () => import('./pages/authentication/authentication.component')
          .then(m => m.AuthenticationComponent)
      },
      {
        path: route.authenticationExpired.path,
        pathMatch: 'full',
        loadComponent: () => import('./pages/authentication-expired/authentication-expired.component')
          .then(m => m.AuthenticationExpiredComponent)
      },
      {
        path: route.subscriptionActivate.path,
        pathMatch: 'full',
        canActivate: [HasAccessTokenGuard],
        loadComponent: () => import('./pages/subscription-activate/subscription-activate.component')
          .then(m => m.SubscriptionActivateComponent)
      },
      {
        path: route.workspaceError.path,
        pathMatch: 'full',
        loadComponent: () => import('./pages/workspace-error/workspace-error.component').then(m => m.WorkspaceErrorComponent)
      },
      {
        path: route.newWorkspace.path,
        pathMatch: 'full',
        loadComponent: () => import('./pages/new-workspace/new-workspace.component').then(m => m.NewWorkspaceComponent)
      },
      {
        path: route.invitationAccept.path,
        pathMatch: 'full',
        loadComponent: () => import('./pages/invitation-accept/invitation-accept.component').then(m => m.InvitationAcceptComponent)
      },
      {
        path: route.signUp.path,
        loadChildren: () => import('./pages/sign-up/sign-up.routes').then(m => m.signUpRoutes)
      },
    ]
  },
  { path: '**', redirectTo: route.root.path }
];
