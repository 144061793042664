import { Injectable, signal, Signal } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouteService } from 'aimmo-core2/app/core/route.service';
import { WorkspaceApiService } from 'aimmo-core2/app/core/services/api/workspace-api.service';
import { WorkspaceStateService } from 'aimmo-core2/app/core/workspace-state.service';
import { Workspace } from 'aimmo-core2/app/shared/models/workspace.model';
import { isEmpty } from 'lodash-es';
import { catchError, defer, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceAzureResolver {
  private static loading = signal(false);

  constructor(
    private service: WorkspaceStateService,
    private workspaceApi: WorkspaceApiService,
    private routeService: RouteService
  ) {
  }

  public static get loadingSignal(): Signal<boolean> {
    return WorkspaceAzureResolver.loading.asReadonly();
  }

  private getWorkspace(): Observable<Workspace> {
    return this.workspaceApi.fetchOneForAzure();
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    WorkspaceAzureResolver.loading.set(true);
    return this.getWorkspace().pipe(
      switchMap(workspace => defer(() => {
        if (isEmpty(workspace)) {
          throw new Error('workspace is not exist');
        } else {
          this.service.workspace.set(workspace);
          WorkspaceAzureResolver.loading.set(false);
          return of(true);
        }
      })),
      catchError(err => {
        console.error('resolver error', err);
        return this.routeService.routeToWorkspaceError(true).pipe(
          map(() => false)
        );
      })
    );
  }
}
