import { I18N_GUARD_CONFIG, SavedLanguageKey, SupportLanguages } from '@aimmo/i18n';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, Router, RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TraceService } from '@sentry/angular-ivy';
import { environment } from 'aimmo-core2/environments/environment';
import { AuthInterceptor } from 'aimmo-core2/app/core/auth.interceptor';
import { azureProviders } from 'aimmo-core2/app/core/azure/azure-providers';
import { isAimmoCoreAzurePhase, isAimmoCorePhase } from 'aimmo-core2/app/shared/util/env-util';
import player from 'lottie-web';
import { provideLottieOptions } from 'ngx-lottie';
import { GoogleOAuthModule } from '@aimmo/components/google-oauth';
import { AimmoCore2I18nLoader } from './aimmo-core2-i18n-loader';
import { routes } from './app.routes';
import { AimmoCore2ErrorHandler } from './core/aimmo-core2-error-handler';
import { ApiInterceptor } from './core/api.interceptor';

const AIMMO_CORE_IMPORTS = [GoogleOAuthModule.forRoot(environment.googleClientId)];
const AIMMO_CORE_AZURE_IMPORTS = [];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    importProvidersFrom(
      TranslateModule.forRoot({ loader: { provide: TranslateLoader, useClass: AimmoCore2I18nLoader }, }),
      BrowserModule,
      BrowserAnimationsModule,
      MatDatepickerModule,
      RouterModule.forRoot(routes, { anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' }),
      isAimmoCorePhase() ? AIMMO_CORE_IMPORTS : AIMMO_CORE_AZURE_IMPORTS
    ),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    {
      provide: I18N_GUARD_CONFIG,
      useValue: { supportLanguages: SupportLanguages.aimmoCore2, savedLanguageKey: SavedLanguageKey.aimmoCore2 }
    },
    { provide: ErrorHandler, useClass: AimmoCore2ErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    provideLottieOptions({ player: () => player }),
    ...(isAimmoCoreAzurePhase() ? azureProviders : []),
    { provide: TraceService, deps: [Router], },
    {
      provide: APP_INITIALIZER, useFactory: () => () => {
      }, deps: [TraceService], multi: true
    },
  ]
};
