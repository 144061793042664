import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { OAuthSSOAzureService } from 'aimmo-core2/app/core/azure/oauth-sso-azure.service';
import { isProdPhaseForAimmoCoreAzure } from 'aimmo-core2/app/shared/util/env-util';
import { environment } from 'aimmo-core2/environments/environment';

function loggerCallback(logLevel: LogLevel, message: string): void {
  if (!isProdPhaseForAimmoCoreAzure()) {
    console.log(message);
  }
}

function factoryForMsalInstance(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureMsalConfig.auth.clientId,
      authority: environment.azureMsalConfig.auth.authority,
      redirectUri: '/oauth2/code/azure',
      // postLogoutRedirectUri: '/oauth2/azure/logout'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

function factoryForMsalInterceptorConfig(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.azureApiConfig.uri, environment.azureApiConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

function factoryForMsalGuardConfig(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.azureApiConfig.scopes
    },
    loginFailedRoute: '/oauth2/azure/error'
  };
}

export const azureProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: factoryForMsalInstance
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: factoryForMsalGuardConfig
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: factoryForMsalInterceptorConfig
  },
  MsalService,
  MsalGuard,
  MsalBroadcastService,
  OAuthSSOAzureService
];
