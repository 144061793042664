import {
  ENTERPRISE_HREF,
  HOMEPAGE_HREF,
  PRIVACY_POLICY_HREF
} from '@aimmo-renew-home/environments/environment.interface';
import { AIMMO_CORE_CONTACT_EN, AIMMO_CORE_CONTACT_FOR_AZURE, AIMMO_CORE_CONTACT_KO } from '@aimmo/constants';
import { Lang } from '@aimmo/i18n';
import { Component, computed, inject, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { route as aimmoCoreLandingRoute } from 'aimmo-core-landing/app/app.route.path';
import { route as aimmoCoreRoute } from 'aimmo-core2/app/app.route.path';
import { isAimmoCoreAzurePhase } from 'aimmo-core2/app/shared/util/env-util';
import { AIMMO_CORE_AZURE_LOGO_PATH, AIMMO_CORE_LOGO_PATH } from '../model';

@Component({
  selector: 'aimmo-core-footer',
  templateUrl: './aimmo-core-footer.component.html',
  styleUrls: ['./aimmo-core-footer.component.scss'],
  standalone: true,
  imports: [
    RouterLink
  ]
})
export class AimmoCoreFooterComponent {
  public readonly PRIVACY_POLICY_HREF = PRIVACY_POLICY_HREF;
  public readonly ENTERPRISE_HREF = ENTERPRISE_HREF;
  public readonly HOMEPAGE_HREF = HOMEPAGE_HREF;
  public readonly isLanding = input(false);
  public readonly aimmoCoreRoute = aimmoCoreRoute;
  public readonly aimmoCoreLandingRoute = aimmoCoreLandingRoute;
  public readonly isAimmoCoreAzurePhase = isAimmoCoreAzurePhase;
  public readonly homePath = computed(() => this.isLanding ? this.aimmoCoreLandingRoute.home.fullPath : this.aimmoCoreRoute.home.fullPath);
  public readonly AIMMO_CORE_AZURE_LOGO_PATH = AIMMO_CORE_AZURE_LOGO_PATH;
  public readonly AIMMO_CORE_LOGO_PATH = AIMMO_CORE_LOGO_PATH;
  private readonly translate = inject(TranslateService);

  public get contactUsDestination(): string {
    if (this.isAimmoCoreAzurePhase()) {
      return AIMMO_CORE_CONTACT_FOR_AZURE;
    } else {
      return this.translate.currentLang === Lang.EN ? AIMMO_CORE_CONTACT_EN : AIMMO_CORE_CONTACT_KO;
    }
  }
}
