import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'aimmo-core2/app/core/auth.service';
import { RouteService } from 'aimmo-core2/app/core/route.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HasAccessTokenGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private routeService: RouteService
  ) {
  }

  public canActivate(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.getAccessToken().pipe(
      switchMap(accessToken => {
        if (accessToken) {
          return of(true);
        } else {
          return this.routeService.routeToAuthentication().pipe(map(() => false));
        }
      })
    );
  }
}
