<header class="header-box">
  <img class="logo" alt="Logo" [src]="logoSvgPath" [routerLink]="logoRouterLink()">
  <div class="header__nav-block">
    <ng-content select="[nav-item]"/>
  </div>
  <div class="header__nav-block">
    @if (!hideLanguageSelector()) {
      <span nav-item-right class="language-menu" [matMenuTriggerFor]="languageMenu" [class.active]="menuButton.menuOpen"
            #menuButton="matMenuTrigger">{{ isEngPage ? 'EN' : 'KO' }}
        <aim-icon class="icon" [icon]="menuButton.menuOpen ? 'chevron-up': 'chevron-down'"/>
      </span>
    }
    <ng-content select="[nav-item-right]"/>
  </div>
</header>

<mat-menu #languageMenu class="aimmo-core-header-dropdown">
  <button mat-menu-item (click)="onChangeLang(Lang.EN)">English</button>
  <button mat-menu-item (click)="onChangeLang(Lang.KO)">Korean</button>
</mat-menu>
