<aim-dialog-header>{{ '@계정_정보@'|translate }}</aim-dialog-header>
<aim-dialog-content>
  <form class="my-account" [formGroup]="formGroup">
    @if (!isAimmoCoreAzurePhase()) {
      <div class="row">
        <div class="row__label">{{ '@이름@'|translate }}</div>
        <div class="row__value">{{ user.name }}</div>
      </div>
    }
    <div class="row">
      <div class="row__label">{{ '@이메일_주소@'|translate }}</div>
      <div class="row__value">{{ user.email }}</div>
    </div>
    <aim-divider/>
    <div class="row">
      <div class="row__label">{{ '@언어@'|translate }}</div>
      <div class="row__value">
        <aim-select class="aimmo-core-select" customPanelClass="aimmo-core-select__panel" style="width:100%;"
                    [formControlName]="FormNames.lang">
          <aim-option [value]="Lang.EN">{{ '@English@'|translate }}</aim-option>
          <aim-option [value]="Lang.KO">{{ '@한국어_L@'|translate }}</aim-option>
        </aim-select>
      </div>
    </div>
    @if (!isAimmoCoreAzurePhase()) {
      <div class="row">
        <div class="row__label">{{ '@이메일_수신동의@'|translate }}</div>
        <div class="row__value">
          <aim-onoff-toggle [formControlName]="FormNames.notification" size="md"/>
        </div>
      </div>
      <!-- rex 당분간 탈퇴 스펙아웃 https://www.figma.com/design/VdtanzPb1djJhy9YGNpA27?node-id=4239-27782&m=dev#915695674-->
          <!--      <aim-divider/>-->
          <!--      <div class="row">-->
          <!--        <div class="row__label">{{ '@계정_삭제@'|translate }}</div>-->
          <!--        <div class="row__value">-->
          <!--          <aim-core-button [state]="CoreButtonState.warning" [rectangle]="true"-->
          <!--                           (click)="onDeleteAccount()">{{ '@삭제@'|translate }}-->
          <!--          </aim-core-button>-->
          <!--        </div>-->
          <!--      </div>-->
    }
  </form>
</aim-dialog-content>
<aim-dialog-actions>
  <aim-core-button [theme]="CoreButtonTheme.ghost" (click)="onClose()">{{ '@취소@'|translate }}</aim-core-button>
  <aim-core-button [disabled]="!valid" (click)="onSave()">{{ '@저장@'|translate }}</aim-core-button>
</aim-dialog-actions>
