import { AimDialogActionsComponent } from '@aimmo/design-system/aim-dialog/aim-dialog-actions';
import { AimDialogContentComponent } from '@aimmo/design-system/aim-dialog/aim-dialog-content';
import { AimDialogHeaderComponent } from '@aimmo/design-system/aim-dialog/aim-dialog-header';
import { AimDialogService } from '@aimmo/design-system/aim-dialog/src';
import { AimDividerComponent } from '@aimmo/design-system/aim-divider/src';
import { AimInputSize } from '@aimmo/design-system/aim-input-model/src';
import { AimOnOffToggleComponent } from '@aimmo/design-system/aim-onoff-toggle/src';
import { AimSelectFieldSize } from '@aimmo/design-system/aim-select/model';
import { AimSelectModule } from '@aimmo/design-system/aim-select/src';
import { AimToast } from '@aimmo/design-system/aim-toast/src';
import { Lang } from '@aimmo/i18n';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalCatchError } from '@falcon/common/decorators';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CoreButtonComponent } from 'aimmo-core2/app/components/core-button/core-button.component';
import { CoreButtonState, CoreButtonTheme } from 'aimmo-core2/app/components/core-button/core-button.model';
import { AuthService } from 'aimmo-core2/app/core/auth.service';
import { UpdateUserParam } from 'aimmo-core2/app/core/services/api/user-api.service';
import { StoreService } from 'aimmo-core2/app/core/store.service';
import {
  CoreConfirmDialogComponent
} from 'aimmo-core2/app/shared/dialogs/core-confirm-dialog/core-confirm-dialog.component';
import {
  CoreConfirmDialogParam,
  makeCoreConfirmDialogConfig
} from 'aimmo-core2/app/shared/dialogs/core-confirm-dialog/core-confirm-dialog.model';
import { User } from 'aimmo-core2/app/shared/models/user.model';
import { isAimmoCoreAzurePhase } from 'aimmo-core2/app/shared/util/env-util';
import { exhaustMap, forkJoin, Observable, Subject, switchMap, tap } from 'rxjs';

enum FormNames {
  lang = 'lang',
  notification = 'notification'
}

@Component({
  selector: 'app-my-account-dialog',
  standalone: true,
  imports: [
    AimDialogActionsComponent,
    AimDialogContentComponent,
    AimDialogHeaderComponent,
    AimSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    CoreButtonComponent,
    AimOnOffToggleComponent,
    AimDividerComponent
  ],
  templateUrl: './my-account-dialog.component.html',
  styleUrl: './my-account-dialog.component.scss'
})
export class MyAccountDialogComponent implements OnInit {
  public readonly AimSelectFieldSize = AimSelectFieldSize;
  public readonly CoreButtonTheme = CoreButtonTheme;
  public readonly user = this.storeService.currentUser();
  public readonly AimInputSize = AimInputSize;
  public readonly isAimmoCoreAzurePhase = isAimmoCoreAzurePhase;
  public readonly Lang = Lang;
  public readonly FormNames = FormNames;
  public formGroup: FormGroup;
  private updateUserSource = new Subject<UpdateUserParam>();
  private deleteAccountSource = new Subject<void>();
  private readonly destroyRef = inject(DestroyRef);
  private readonly fb = inject(FormBuilder);
  private initialFormGroupValues: UpdateUserParam;

  constructor(
    private dialogRef: MatDialogRef<MyAccountDialogComponent>,
    private dialog: AimDialogService,
    private t: TranslateService,
    private storeService: StoreService,
    private authService: AuthService,
    private toast: AimToast
  ) {
  }

  public get valid(): boolean {
    return this.formGroup.dirty && this.formGroup.valid && !this.isEqualsFormValues(this.formGroup.value, this.initialFormGroupValues);
  }

  private get updateUserAction$(): Observable<User> {
    return this.updateUserSource.pipe(
      exhaustMap(param => this.updateUser(param))
    );
  }

  private get deleteAccountAction$(): Observable<boolean> {
    return this.deleteAccountSource.pipe(
      switchMap(() => this.openDeleteAccountConfirmDialog())
    );
  }

  public ngOnInit(): void {
    forkJoin([
      this.updateUserAction$,
      this.deleteAccountAction$,
    ]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    this.initializeForm();
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onSave(): void {
    if (this.valid) {
      const param: UpdateUserParam = this.formGroup.value;
      // TODO rex https://app.asana.com/0/1202320036187028/1208141216140062/f BE 수정되면 제거
      param.name = this.user.name;
      this.updateUserSource.next(param);
    }
  }

  public onDeleteAccount(): void {
    this.deleteAccountSource.next();
  }

  private isEqualsFormValues(currentValues: UpdateUserParam, initialValues: UpdateUserParam): boolean {
    return JSON.stringify(currentValues) === JSON.stringify(initialValues);
  }

  @GlobalCatchError
  private openDeleteAccountConfirmDialog(): Observable<boolean> {
    return this.dialog.open<CoreConfirmDialogComponent, CoreConfirmDialogParam, boolean>(
      CoreConfirmDialogComponent, {
        ...makeCoreConfirmDialogConfig(
          this.t.instant('@계정_삭제@'),
          this.t.instant('@계정_삭제_시_복구가_어렵습니다_정말로_계정을_삭제하시겠습니까@'),
          this.t.instant('@삭제@'),
          this.t.instant('@취소@'),
          CoreButtonState.warning
        ),
      }).afterClosed();
  }

  @GlobalCatchError
  private updateUser(param: UpdateUserParam): Observable<User> {
    return this.authService.updateUser(param).pipe(
      switchMap(() => this.authService.getUser()),
      tap(() => {
        this.toast.openPositive(this.t.instant('@저장되었습니다@'));
        this.dialogRef.close(true);
      }),
    );
  }

  private initializeForm(): void {
    this.formGroup = this.fb.group({
      [FormNames.lang]: this.fb.control(this.user.lang, Validators.required),
      [FormNames.notification]: this.fb.control(this.user.notification)
    });
    this.initialFormGroupValues = this.formGroup.value;
  }
}
