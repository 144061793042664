<div class="link-wrapper">
  <img class="logo" alt="Logo" [routerLink]="homePath()"
       [src]="isAimmoCoreAzurePhase() ? AIMMO_CORE_AZURE_LOGO_PATH : AIMMO_CORE_LOGO_PATH">
  <div class="link-list">
    <a [href]="HOMEPAGE_HREF" target="_blank">About AIMMO</a>
    <a [href]="ENTERPRISE_HREF" target="_blank">Enterprise</a>
    <a [href]="PRIVACY_POLICY_HREF" target="_blank">Privacy & Terms</a>
    <a [href]="contactUsDestination" target="_blank">Contact us</a>
  </div>
</div>
<div class="copyright">Copyright ⓒ 2024 AIMMO, Inc. All rights reserved.</div>
