import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'aimmo-core2/environments/environment';
import { defer, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const isAimmoCoreAPI = this.isAimmoCoreApiUrl(request.url);

    return defer(() => {
      request = request.clone({
        url: (isAimmoCoreAPI ? environment.apiUrl : '') + request.url
      });
      return next.handle(request);
    });
  }

  private isAimmoCoreApiUrl(url: string): boolean {
    const rxApi = /^\//;
    const rxAssets = /^\/assets/;
    return !rxAssets.test(url) && rxApi.test(url);
  }
}
