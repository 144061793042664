import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'aimmo-core2/app/core/auth.service';
import { RouteService } from 'aimmo-core2/app/core/route.service';
import { TokenManagementService } from 'aimmo-core2/app/core/token-management.service';
import { isAimmoCorePhase } from 'aimmo-core2/app/shared/util/env-util';
import { HttpStatus } from 'app/shared/constants/http-status';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

// 당분간 고정 인증토큰 사용
const MODEL_API_BEARER_TOKEN = 'uX7yfCRjew76enuSCKVAj2We4ZY384';

function makeAuthorizationToken(token: string): string {
  return `Bearer ${token}`;
}


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private auth = inject(AuthService);

  constructor(
    private route: RouteService,
    private router: Router,
    private token: TokenManagementService,
  ) {
  }

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const isModelApi = this.isModelApi(request.url);
    const isAimmoCoreAPI = this.isAimmoCoreApiUrl(request.url);

    return this.auth.getAccessToken().pipe(
      switchMap(token => next.handle(
          isAimmoCoreAPI
            ? request.clone({ setHeaders: { 'x-auth-token': token || '' } })
            : isModelApi
              ? request.clone({ setHeaders: { Authorization: makeAuthorizationToken(MODEL_API_BEARER_TOKEN) } })
              : request
        )
      ),
      observer => isAimmoCorePhase() ? this.handleHttpError(observer) : observer
    );
  }

  private handleHttpError(source: Observable<any>): Observable<any> {
    return source.pipe(
      catchError(error => {
        if (HttpStatus.T_401_UNAUTHORIZED === error.status) {
          this.route.setRedirectUrl(this.router.url);
          return forkJoin([
            this.token.clearTokenAll(),
            this.route.routeToLogin(false)
          ]).pipe(
            switchMap(() => throwError(() => error))
          );
        }
        return throwError(() => error);
      })
    );
  }

  private isModelApi(url: string): boolean {
    const reg = new RegExp(
      `^(http|https):\\/\\/(models-apis|demo-dp-api\\.bluewhale\\.team|models\\.aimmo-mlops\\.com)`, 'i'
    );

    return reg.test(url);
  }

  private isAimmoCoreApiUrl(url: string): boolean {
    const rxApi = /^\//;
    const rxAssets = /^\/assets/;
    return !rxAssets.test(url) && rxApi.test(url);
  }
}
