import { HttpErrorResponse } from '@angular/common/http';
import { captureException } from '@sentry/angular-ivy';

export function captureExceptionToSentry(err: any): void {
  const extractedError = extractError(err);
  extractedError
    ? captureException(extractedError)
    : captureException('Handled unknown error. maybe script error', {
      extra: {
        capturedError: err,
        targetInnerHTML: err?.target?.innerHTML,
        targetSrc: err?.target?.src
      }
    });
}

export function extractError(error: any): null | string | Error {
  // Try to unwrap zone.js error.
  // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
  if (error && error.ngOriginalError) {
    error = error.ngOriginalError;
  }

  // We can handle messages and Error objects directly.
  if (typeof error === 'string' || error instanceof Error) {
    return error;
  }

  // If it's http module error, extract as much information from it as we can.
  if (error instanceof HttpErrorResponse) {
    // The `error` property of http exception can be either an `Error` object, which we can use directly...
    if (error.error instanceof Error) {
      return error.error;
    }

    // ... or an`ErrorEvent`, which can provide us with the message but no stack...
    if (error.error instanceof ErrorEvent) {
      return error.error.message;
    }

    // ...or the request body itself, which we can use as a message instead.
    if (typeof error.error === 'string') {
      return `Server returned code ${error.status} with body "${error.error}"`;
    }

    // If we don't have any detailed information, fallback to the request message itself.
    return error.message;
  }

  // Skip if there's no error, and let user decide what to do with it.
  return null;
}
