import { Lang } from '@aimmo/i18n';
import { TranslateLoader } from '@ngx-translate/core';
import { SUPPORT_LANGUAGES } from 'aimmo-core2/environments/environment.interface';
import { defer, forkJoin, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

export class AimmoCore2I18nLoader implements TranslateLoader {
  public getTranslation(lang: Lang): Observable<string> {
    return of(SUPPORT_LANGUAGES.includes(lang) ? lang : Lang.EN).pipe(
      switchMap(country => {
        const translations = {
          aimmoComponent: defer(() =>
            import(`../../../../projects/aimmo-components/i18n/${country}.json`).then(module => module.default)),
          aimmoCore2: defer(() =>
            import(`../assets/i18n/${country}.json`).then(module => module.default)),
        };
        return forkJoin(translations);
      }),
      map(({ aimmoComponent, aimmoCore2 }) => ({ ...aimmoComponent, ...aimmoCore2, })
      )
    );
  }
}
