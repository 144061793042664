import { AimmoCoreFooterComponent } from '@aimmo/components/aimmo-core/aimmo-core-footer';
import { AimmoCoreHeaderComponent } from '@aimmo/components/aimmo-core/aimmo-core-header';
import { AIMMO_CORE_DOCS_URL_EN, AIMMO_CORE_DOCS_URL_KO } from '@aimmo/constants';
import { AimDialogService } from '@aimmo/design-system/aim-dialog/src';
import { AimIconComponent } from '@aimmo/design-system/aim-icon/src';
import { AimToast } from '@aimmo/design-system/aim-toast/src';
import { Lang } from '@aimmo/i18n';
import { BreakpointState } from '@angular/cdk/layout';
import {
  afterNextRender,
  Component,
  computed,
  DestroyRef,
  inject,
  NgZone,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { GlobalCatchError } from '@falcon/common/decorators';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { route } from 'aimmo-core2/app/app.route.path';
import { CoreIconComponent } from 'aimmo-core2/app/components/core-icon/core-icon.component';
import { CoreIcon } from 'aimmo-core2/app/components/core-icon/core-icon.model';
import { AuthService } from 'aimmo-core2/app/core/auth.service';
import { AimmoCoreBreakpointService } from 'aimmo-core2/app/core/services/aimmo-core-breakpoint.service';
import { StoreService } from 'aimmo-core2/app/core/store.service';
import { WorkspaceAzureResolver } from 'aimmo-core2/app/core/workspace-azure.resolver';
import { WorkspaceCoreResolver } from 'aimmo-core2/app/core/workspace-core.resolver';
import { WorkspaceStateService } from 'aimmo-core2/app/core/workspace-state.service';
import { MyAccountDialogComponent } from 'aimmo-core2/app/shared/dialogs/my-account-dialog/my-account-dialog.component';
import { Workspace } from 'aimmo-core2/app/shared/models/workspace.model';
import { isAimmoCoreAzurePhase, isAimmoCorePhase } from 'aimmo-core2/app/shared/util/env-util';
import { isNil } from 'lodash-es';
import { forkJoin, fromEvent, Observable, skip, Subject, switchMap } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { MobileHeaderComponent } from './mobile-header/mobile-header.component';

@Component({
  selector: 'app-layout-top-bottom',
  templateUrl: './layout-top-bottom.component.html',
  styleUrls: ['./layout-top-bottom.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    MobileHeaderComponent,
    AimmoCoreHeaderComponent,
    AimmoCoreFooterComponent,
    AimIconComponent,
    RouterLink,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    TranslateModule,
    RouterLinkActive,
    CoreIconComponent
  ],
})
export class LayoutTopBottomComponent implements OnInit {
  public readonly route = route;
  public readonly isAimmoCoreAzurePhase = isAimmoCoreAzurePhase();
  public readonly isAimmoCorePhase = isAimmoCorePhase();
  public readonly logoDestination = isAimmoCoreAzurePhase() ? route.dashboard.fullPath : route.home.fullPath;
  public user = computed(() => this.store.currentUser());
  public workspaces = computed(() => this.store.workspaces());
  public workspace = computed(() => this.workspaceStateService.workspace());
  public readonly AIMMO_CORE_DOCS_URL = computed<string>(() => this.store.currentUser()?.lang === Lang.EN ? AIMMO_CORE_DOCS_URL_EN : AIMMO_CORE_DOCS_URL_KO);
  public readonly CoreIcon = CoreIcon;
  public readonly isMobile = computed(() => this.breakpointsService.isMobile());
  private readonly destroyRef = inject(DestroyRef);
  private readonly myAccountDialogSource = new Subject<void>();
  @ViewChildren(MatMenuTrigger) private readonly trigger: QueryList<MatMenuTrigger>;

  constructor(
    public store: StoreService,
    private dialog: AimDialogService,
    private zone: NgZone,
    private t: TranslateService,
    private renderer: Renderer2,
    private toast: AimToast,
    private auth: AuthService,
    private workspaceStateService: WorkspaceStateService,
    private breakpointsService: AimmoCoreBreakpointService,
    private workspaceCoreResolver: WorkspaceCoreResolver,
    private router: Router
  ) {
    afterNextRender(() => {
      this.renderer.addClass(document.body, this.t.currentLang);
      fromEvent(window, 'scroll').pipe(
        debounceTime(17),
        tap(() => this.zone.run(() => this.trigger.forEach(triggerInstance => triggerInstance.closeMenu()))),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe();
    });
  }

  public get saveWorkspaceIdWhenWorkspaceChangedAction$(): Observable<string> {
    return this.workspaceStateService.workspace$.pipe(
      filter(workspace => !!workspace),
      switchMap(workspace => {
        const userId = this.store.currentUser().userId;
        return this.workspaceStateService.saveLastAccessedWorkspaceId(userId, workspace.id);
      })
    );
  }

  public get workspaceRefreshAction$(): Observable<boolean> {
    return this.workspaceStateService.workspaceResolveEvent$.pipe(
      filter(v => !isNil(v)),
      switchMap(({ workspace, afterResolved }) =>
        this.workspaceCoreResolver.runWorkspaceResolver(this.router.url, workspace?.id).pipe(
          switchMap(() => afterResolved())
        )
      )
    );
  }

  public get isLoading(): boolean {
    return isAimmoCorePhase()
      ? WorkspaceCoreResolver.loadingSignal()
      : WorkspaceAzureResolver.loadingSignal();
  }

  private get myAccountDialogAction$(): Observable<boolean> {
    return this.myAccountDialogSource.pipe(
      switchMap(() => this.openMyAccountDialog())
    );
  }

  private get layoutChangesAction$(): Observable<BreakpointState> {
    return this.breakpointsService.layoutChangesEvent$;
  }

  public onOpenMyAccountDialog(): void {
    this.myAccountDialogSource.next();
  }

  public ngOnInit(): void {
    forkJoin([
      this.layoutChangesAction$,
      this.myAccountDialogAction$,
      this.saveWorkspaceIdWhenWorkspaceChangedAction$,
      this.workspaceRefreshAction$
    ]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  public onChangeWorkspace(workspace: Workspace): void {
    if (this.workspace().id === workspace.id) {
      return;
    }

    this.workspaceStateService.setWorkspaceWithResolve(workspace);
    this.toast.openPositive(this.t.instant('@워크스페이스가_workspaceName_으로_변경되었습니다@', { workspaceName: this.workspace().name }));
  }

  public onLogout(): void {
    this.auth.logout().subscribe();
  }

  @GlobalCatchError
  private openMyAccountDialog(): Observable<boolean> {
    return this.dialog.open<MyAccountDialogComponent, void>(
      MyAccountDialogComponent, { panelClass: 'aimmo-core-dialog', minWidth: '432px', }
    ).afterClosed();
  }

  public onClickTest(event: MouseEvent): void {
    event.preventDefault();
    if (true) {
      throw new Error('ryan sourcemap test man');
    }
  }
}
