import { HttpStatus } from '@aimmo/constants/http';
import { ErrorHandler, Injectable, Signal, signal } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { retryCountExcludedStatusCodes } from '@falcon/common/utils';
import { AuthService, AuthStateForCore } from 'aimmo-core2/app/core/auth.service';
import { RouteService } from 'aimmo-core2/app/core/route.service';
import { WorkspaceApiService } from 'aimmo-core2/app/core/services/api/workspace-api.service';
import { StoreService } from 'aimmo-core2/app/core/store.service';
import { WorkspaceStateService } from 'aimmo-core2/app/core/workspace-state.service';
import { Workspace, WorkspaceRole } from 'aimmo-core2/app/shared/models/workspace.model';
import { isEmpty, isEqual } from 'lodash-es';
import { catchError, defer, Observable, of, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceCoreResolver {
  private static loading = signal(false);

  constructor(
    private service: WorkspaceStateService,
    private workspaceApi: WorkspaceApiService,
    private routeService: RouteService,
    private storeService: StoreService,
    private errorHandler: ErrorHandler,
    private auth: AuthService
  ) {
  }

  public static get loadingSignal(): Signal<boolean> {
    return WorkspaceCoreResolver.loading.asReadonly();
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.getAuthState().pipe(
      switchMap(authState => {
        if (authState === AuthStateForCore.authorized) {
          const userId = this.storeService.currentUser().userId;
          return this.service.getLastAccessedWorkspaceId(userId).pipe(
            switchMap(workspaceId => this.runWorkspaceResolver(state.url, workspaceId))
          );
        }
        return of(true);
      })
    );
  }

  public runWorkspaceResolver(redirectUrl: string, workspaceId: string): Observable<boolean> {
    WorkspaceCoreResolver.loading.set(true);

    return this.getWorkspaces().pipe(
      retryCountExcludedStatusCodes({
        maxRetryAttempts: 2, scalingDuration: 700,
        excludedStatusCodes: [HttpStatus.T_401_UNAUTHORIZED, HttpStatus.T_403_FORBIDDEN, HttpStatus.T_404_NOT_FOUND]
      }),
      switchMap(workspaces => defer(() => {
        if (isEmpty(workspaces)) {
          return this.redirectToNewWorkspace();
        } else {
          this.storeService.setWorkspaces(workspaces);
          return this.redirectToSuggestedWorkspace(workspaces, workspaceId);
        }
      })),
      tap(() => WorkspaceCoreResolver.loading.set(false)),
      catchError(err => {
        console.error('resolver error', err);
        this.errorHandler.handleError(err);
        this.routeService.setRedirectUrl(redirectUrl);
        return this.routeService.routeToWorkspaceError(true).pipe(
          map(() => null)
        );
      })
    );
  }

  private getWorkspaces(): Observable<Workspace[]> {
    return this.workspaceApi.workspaces();
  }

  private redirectToNewWorkspace(): Observable<boolean> {
    return this.routeService.routeToNewWorkspace(true).pipe(
      switchMap(() => throwError(() => null))
    );
  }

  private redirectToSuggestedWorkspace(workspaces: Workspace[], workspaceId: string): Observable<WorkspaceRole> {
    return defer(() => {
      const suggestWorkspace = this.findSuggestWorkspace(workspaces, workspaceId);
      return of(suggestWorkspace);
    }).pipe(
      switchMap(workspace => this.fetchWorkspaceRole(workspace.id).pipe(
        tap(({ role }) => {
          this.service.workspace.set(workspace);
          this.service.workspaceRole.set(role);
        })
      ))
    );
  }

  private findSuggestWorkspace(workspaces: Workspace[], targetWorkspaceId: string): Workspace {
    let suggestWorkspace: Workspace;
    if (!isEmpty(targetWorkspaceId)) {
      suggestWorkspace = workspaces.find(({ id }) => isEqual(id, targetWorkspaceId));
    }
    return suggestWorkspace ?? workspaces[0];
  }

  private fetchWorkspaceRole(workspaceId: string): Observable<WorkspaceRole> {
    return this.workspaceApi.role(workspaceId).pipe(
      catchError(err => {
        console.error('workspace role is not exist');
        return throwError(() => err);
      })
    );
  }
}
