import { isLocalHost } from '@aimmo/utils/dev-tool';
import { captureExceptionToSentry, SENTRY_MATCHER_LOAD_CHUNK_ERROR } from '@aimmo/utils/sentry';
import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { init } from '@sentry/angular-ivy';
import { environment } from 'aimmo-core2/environments/environment';
import { HttpStatus } from '@aimmo/constants/http';

@Injectable()
export class AimmoCore2ErrorHandler implements ErrorHandler {
  public readonly isPlatformBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  constructor(
    private t: TranslateService,
  ) {
    if (this.isPlatformBrowser) {
      init({
        ...environment.sentry,
        ignoreErrors: [
          SENTRY_MATCHER_LOAD_CHUNK_ERROR
        ]
      });
    }
  }

  public handleError(error: { originalError: any; }): void {
    if (error instanceof HttpErrorResponse) {
      this.handleHttpError(error);
    } else {
      this.captureError(error);
    }
  }

  private handleHttpError(error: HttpErrorResponse): void {
    switch (error.status) {
      // 401, 461 은 interceptor 에서 처리되어야함. 여기서 호출되면 오류.
      case HttpStatus.T_401_UNAUTHORIZED:
      case HttpStatus.T_428_PRECONDITION_REQUIRED:
      case HttpStatus.T_461_TOKEN_EXPIRED:
        alert((error && error.error && error.error.message) || this.t.instant('@통신_중_오류가_발생했습니다@'));
        this.captureError(error);
        break;
      case HttpStatus.T_400_BAD_REQUEST:
      case HttpStatus.T_404_NOT_FOUND:
      // case HttpStatus.T_403_FORBIDDEN:
      // case HttpStatus.T_406_NOT_ACCEPTABLE:
      // case HttpStatus.T_408_REQUEST_TIMEOUT:
      case HttpStatus.T_409_CONFLICT:
      case HttpStatus.T_412_PRECONDITION_FAILED:
      case HttpStatus.T_422_UNPROCESSABLE_ENTITY:
      case HttpStatus.T_449_RETRY_LATER:
        alert((error && error.error && error.error.message) || this.t.instant('@통신_중_오류가_발생했습니다@'));
        break;

      case HttpStatus.T_423_LOCKED:
        this.captureError(error);
        break;
      case HttpStatus.T_504_GATEWAY_TIMEOUT:
        alert((error && error.error && error.error.message) || this.t.instant('@서버가_제_시간_내_응답하지_않았습니다@'));
        this.captureError(error);
        break;
      default:
        alert((error && error.error && error.error.message) || this.t.instant('@통신_중_오류가_발생했습니다@'));
        this.captureError(error);
        break;
    }
  }

  // noinspection JSMethodCanBeStatic
  private captureError(err: any): void {
    if (this.isPlatformBrowser && isLocalHost() || !environment.sentry.dsn) {
      console.error('sentry', err);
      return;
    }


    if (err && err.status && [
      HttpStatus.T_401_UNAUTHORIZED,
      HttpStatus.T_404_NOT_FOUND,
      HttpStatus.T_406_NOT_ACCEPTABLE,
      HttpStatus.T_409_CONFLICT,
      HttpStatus.T_412_PRECONDITION_FAILED,
      HttpStatus.T_422_UNPROCESSABLE_ENTITY,
      HttpStatus.T_428_PRECONDITION_REQUIRED
    ].includes(err.status)) {
      console.log('not send sentry');
      return;
    }

    captureExceptionToSentry(err);
  }
}
