import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-layout-blank',
  templateUrl: './layout-blank.component.html',
  styleUrls: ['./layout-blank.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet
  ]
})
export class LayoutBlankComponent {
}
