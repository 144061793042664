import { AimIconComponent } from '@aimmo/design-system/aim-icon/src';
import { I18nGuard, Lang } from '@aimmo/i18n';
import {
  afterNextRender,
  Component,
  DestroyRef,
  Inject,
  input,
  NgZone,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';
import { GlobalCatchError } from '@falcon/common/decorators';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { isAimmoCoreAzurePhase } from 'aimmo-core2/app/shared/util/env-util';
import { WINDOW } from 'ngx-window-token';
import { fromEvent, Observable, Subject, switchMap } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { AIMMO_CORE_AZURE_LOGO_PATH, AIMMO_CORE_LOGO_PATH } from '../model';

@Component({
  selector: 'aimmo-core-header',
  templateUrl: './aimmo-core-header.component.html',
  styleUrls: ['./aimmo-core-header.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    RouterLink,
    AimIconComponent,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
  ],
  host: { '[class.hidden]': 'isHidden' },
})
export class AimmoCoreHeaderComponent implements OnInit {
  public logoRouterLink = input('/');
  public hideLanguageSelector = input(false);
  public readonly Lang = Lang;
  public isHidden = false;
  private readonly changeLangSource = new Subject<Lang>();
  private lastScrollValue = 0;
  @ViewChildren(MatMenuTrigger) private readonly trigger: QueryList<MatMenuTrigger>;

  constructor(
    private zone: NgZone,
    private router: Router,
    private translate: TranslateService,
    private i18nGuard: I18nGuard,
    private destroyRef: DestroyRef,
    private loader: TranslateLoader,
    @Inject(WINDOW) private window: Window
  ) {
    afterNextRender(() => {
      fromEvent(window, 'scroll').pipe(
        debounceTime(17),
        tap(() => {
          this.zone.run(() => {
            const currentScrollValue: number = window.scrollY || document.documentElement.scrollTop;
            this.isHidden = currentScrollValue > this.lastScrollValue;
            this.lastScrollValue = currentScrollValue <= 0 ? 0 : currentScrollValue;
            this.trigger.forEach(triggerInstance => triggerInstance.closeMenu());
          });
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe();
      const savedScrollPosition = sessionStorage.getItem('aimmoCoreScrollPosition');
      if (savedScrollPosition) {
        setTimeout(() => window.scrollTo(0, parseInt(savedScrollPosition, 10)));
        sessionStorage.removeItem('aimmoCoreScrollPosition');
      }
    });
  }

  public get logoSvgPath(): string {
    return isAimmoCoreAzurePhase() ? AIMMO_CORE_AZURE_LOGO_PATH : AIMMO_CORE_LOGO_PATH;
  }

  public get isEngPage(): boolean {
    return this.translate.currentLang === Lang.EN;
  }

  public get lang(): string {
    return this.translate.currentLang;
  }

  private get changeLangAction$(): Observable<string> {
    return this.changeLangSource.pipe(
      switchMap(lang => this.changeLang(lang))
    );
  }

  public ngOnInit(): void {
    this.changeLangAction$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  public isCurrentPath(comparePath: string): boolean {
    return this.router.url === comparePath;
  }

  public onChangeLang(lang: string): void {
    if (this.translate.currentLang === lang) {
      return;
    }
    this.translate.currentLang = lang;
    this.changeLangSource.next(lang as Lang);
  }

  @GlobalCatchError
  private changeLang(lang: Lang): Observable<string> {
    return this.loader.getTranslation(lang).pipe(
      tap(t => this.translate.setTranslation(lang, t)),
      switchMap(() => this.i18nGuard.setLanguage(lang))
    );
  }
}
