import { Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { noop } from 'lodash-es';

@Directive()
export abstract class AbstractValueAccessor implements ControlValueAccessor {
  public onChange: (_) => void = noop;
  public onTouched: () => void = noop;
  public innerValueSource: boolean;

  abstract writeValue(value: boolean): void;

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
