import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { AuthService, BroadcastMessage } from 'aimmo-core2/app/core/auth.service';
import { RouteService } from 'aimmo-core2/app/core/route.service';
import { isAimmoCoreAzurePhase } from 'aimmo-core2/app/shared/util/env-util';
import { forkJoin, Observable, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  public readonly isAimmoCoreAzurePhase = isAimmoCoreAzurePhase;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private authService: AuthService,
    private routeService: RouteService
  ) {
  }

  private get authAzureBroadCastMessageAction$(): Observable<boolean> {
    return this.authService.authAzureBroadcastMessage$().pipe(
      filter(message => message.data === BroadcastMessage.loginComplete),
      switchMap(() => this.routeService.routeToAuthenticationExpired())
    );
  }

  public ngOnInit(): void {
    forkJoin([
      this.authAzureBroadCastMessageAction$
    ]).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }
}
