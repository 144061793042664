@if (isMobile()) {
  <aimmo-core-mobile-header class="mobile-header" [logoRouterLink]="logoDestination"
                            [hideLanguageSelector]="true"/>
} @else {
  <aimmo-core-header class="header" [logoRouterLink]="logoDestination" [hideLanguageSelector]="true">
    <a nav-item routerLinkActive="active"
       [routerLink]="route.products.fullPath">{{ '@Products@'|translate }}</a>
    <a nav-item routerLinkActive="active"
       [routerLink]="route.usecase.fullPath">{{ '@Use_Case@'|translate }}</a>
    <a nav-item routerLinkActive="active"
       [routerLink]="route.dashboard.fullPath">{{ '@Dashboard@'|translate }}</a>
    @if (!isAimmoCoreAzurePhase) {
      <!--      todo core 8월 버전에서 메뉴 hide -->
          <!--      <a nav-item routerLinkActive="active"-->
          <!--         [routerLink]="route.pricing.fullPath">{{ '@Pricing@'|translate }}</a>-->
    }
    <a nav-item routerLinkActive="active"
       [href]="AIMMO_CORE_DOCS_URL()" target="_blank">{{ '@Document@'|translate }}
      <aim-icon class="icon" icon="popup"/>
    </a>
    <a nav-item routerLinkActive="active" target="_blank" (click)="onClickTest($event)">test
      <aim-icon class="icon" icon="popup"/>
    </a>
    @if (isAimmoCoreAzurePhase) {
      <span nav-item-right>{{ user()?.name }}</span>
    } @else {
      <span nav-item-right [matMenuTriggerFor]="workspaceMenu">
        {{ workspace()?.name }}
        <aim-icon class="icon" icon="chevron-down"/>
      </span>
    }
    <span nav-item-right [matMenuTriggerFor]="userMenu">
      <aim-core-icon [icon]="CoreIcon.user"/>
    </span>
    <mat-menu #workspaceMenu class="aimmo-core-header-dropdown">
      <button mat-menu-item class="border-bottom" [routerLink]="route.workspaceProfile.path">
        {{ '@워크스페이스_프로필@'|translate }}
      </button>
      <button mat-menu-item [matMenuTriggerFor]="changeWorkspace">
        {{ '@워크스페이스_변경@'|translate }}
        <aim-icon class="icon" icon="chevron-right"/>
      </button>
      <a mat-menu-item [routerLink]="route.newWorkspace.fullPath">{{ '@워크스페이스_추가@'|translate }}</a>
    </mat-menu>
    <mat-menu #userMenu class="aimmo-core-header-dropdown">
      @if (isAimmoCoreAzurePhase) {
        <div class="user-info">
          <div class="user-info__email-azure">{{ user()?.email }}</div>
        </div>
      } @else {
        <div class="user-info">
          <div class="user-info__name">{{ user()?.name }}</div>
          <div class="user-info__email">{{ user()?.email }}</div>
        </div>
      }
      <button mat-menu-item (click)="onOpenMyAccountDialog()">{{ '@계정_정보@'|translate }}</button>

      @if (isAimmoCorePhase && !!user()) {
        <button mat-menu-item (click)="onLogout()">{{ '@로그아웃@'|translate }}</button>
      }
    </mat-menu>
    <mat-menu #changeWorkspace="matMenu" class="aimmo-core-header-dropdown scroll" xPosition="before">
      @for (workspace of workspaces(); track workspace.id) {
        <button mat-menu-item (click)="onChangeWorkspace(workspace)">{{ workspace.name }}</button>
      }
    </mat-menu>
  </aimmo-core-header>
}
<div class="router-outlet-wrapper">
  @if (!isLoading) {
    <router-outlet/>
  }
</div>
<aimmo-core-footer/>

