<div class="mobile-header">
  <img class="logo" src="assets/svg/aimmo-core-logo-mobile.svg" alt="aimmo-logo"
       [routerLink]="logoRouterLink()">
  <img class="header-button"
       [src]="isOpen() ? 'assets/icons/menu-close.svg' : 'assets/icons/menu.svg'" alt="menu-toggle-button"
       (click)="onToggleOpen()">
</div>
<div class="backdrop" [class.visible]="isOpen()" (click)="onClickBackdrop()"></div>
<header class="header-box" [class.open]="isOpen()">
  <div class="nav">
    <a class="item" routerLinkActive="active"
       [routerLink]="route.products.fullPath">{{ '@Products@'|translate }}</a>
    <a class="item" routerLinkActive="active"
       [routerLink]="route.usecase.fullPath">{{ '@Usecase@'|translate }}</a>
    <a class="item" routerLinkActive="active"
       [routerLink]="route.dashboard.fullPath">{{ '@Dashboard@'|translate }}</a>
    @if (!isAimmoCoreAzurePhase) {
      <a class="item" routerLinkActive="active"
         [routerLink]="route.pricing.fullPath">{{ '@Pricing@'|translate }}</a>
    }
    <a class="item" routerLinkActive="active"
       [href]="AIMMO_CORE_DOCS_URL()" target="_blank">{{ '@Document@'|translate }}</a>
    @if (!hideLanguageSelector()) {
      <span class="item" [class.highlight]="isLangMenuOpen()"
            (click)="onToggleLangMenuOpen()">{{ isEngPage ? 'ENG' : 'KOR' }}
        <aim-icon class="icon" [icon]="isLangMenuOpen() ? 'chevron-up' : 'chevron-down'"/></span>
      <div *ngIf="isLangMenuOpen()" class="lang-dropdown">
        <button class="item" (click)="onChangeLang(Lang.EN)">- {{ '@English@'|translate }}</button>
        <button class="item" (click)="onChangeLang(Lang.KO)">- {{ '@Korean@'|translate }}</button>
      </div>
    }
    <a class="item" target="_blank" href="#">{{ '@Contact@'|translate }}</a>
  </div>
</header>
